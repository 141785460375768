import React, { useState } from "react";
import Sidenav from "./Sidenav";
import Header from "./Header";
import axios from "axios";

function Notification() {
  const [selectedOption, setSelectedOption] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const PublishNotification = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/notification/fcmpushnotificationUserApp",
        method: "post",
        baseURL: "https://api.vijayhomeservicebengaluru.in/api",
        headers: { "content-type": "application/json" },
        data: { title: title, body: body, imageUrl: imageUrl },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
          alert("Notification sent successfully!");
          window.location.assign("/notification");
        } else {
          alert(response.data.error);
        }
      });
    } catch (error) {
      console.log("Error sending notification", error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidenav />
        </div>
        <div className="col-md-10">
          <Header />

          <div className="content mt-4 p-4">
            <h4 className="text-primary mb-4">Push Notification</h4>

            <form onSubmit={PublishNotification} className="mb-4">
              <div className="form-group mb-3">
                <label className="form-label">Title</label>
                <textarea
                  className="form-control"
                  placeholder="Enter notification title..."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label">Description (optional)</label>
                <textarea
                  className="form-control"
                  placeholder="Enter notification description..."
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label">Image URL (optional)</label>
                <textarea
                  className="form-control"
                  placeholder="Enter image URL..."
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                />
              </div>

              <button type="submit" className="btn btn-success w-100">
                Send Notification
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
