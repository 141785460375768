import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidenav from "./Sidenav";
import DataTable from "react-data-table-component";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function UserManagement() {
  const [searchItems, setSearchItems] = useState("");
  const [Remarks, setremarks] = useState("");
  const [totalRecords, setTotalRecords] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterdata, setfilterdata] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setdata] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setdata(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.vijayhomesuperadmin.in/api/getcustomerdatapagewise?page=${currentPage}&search=${searchItems}`
        );
        const result = await response.json();
        console.log("result?.customers", result?.customers);

        setfilterdata(result?.customers);
        setTotalRecords(result?.totalRecords); // Assuming you have a state variable for total records
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, searchItems]);

  const deleteuser = async (id) => {
    axios({
      method: "post",
      url: "https://api.vijayhomesuperadmin.in/api/deletetercustomer/" + id,
    })
      .then(function (response) {
        //handle success
        console.log(response);
        alert("Deleted successfully");
        window.location.reload();
      })
      .catch(function (error) {
        //handle error
        console.log(error.response.data);
      });
  };

  const updateremarks = async (e) => {
    e.preventDefault();
    try {
      console.log("data", data);
      const config = {
        url: `/updatedRemarks/${data._id}`,
        method: "post",
        baseURL: "https://api.vijayhomesuperadmin.in/api",
        // data: formdata,
        headers: { "content-type": "application/json" },
        data: {
          Remarks: Remarks,
        },
      };
      await axios(config).then(function (response) {
        if (response.status === 200) {
        
   alert("successfuly updated remarks");
 window.location.reload();
        }
      });
    } catch (error) {
      console.error(error);
      alert(" Not Added");
    }
  };
  const columns = [
    {
      name: "Sl  No",
      selector: (row, index) => (currentPage - 1) * 15 + index + 1,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },

    {
      name: "Contact",
      selector: (row) => row.mainContact,
    },

    {
      name: "Remarks",
      selector: (row) => (row.Remarks ? row.Remarks : "-"),
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a onClick={() => deleteuser(row?._id)} className="hyperlink mx-1">
            Delete
          </a>{" "}
          /{" "}
          <a onClick={() => handleShow(row)} className="hyperlink mx-1">
            Remarks
          </a>
        </div>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-2">
        <Sidenav />
      </div>
      <div className="col-md-10 ">
        <Header />
        <div className="row">
          <div className="mt-5">
            <input
              type="text"
              placeholder="Search by name /email"
              className="w-25 form-control"
              value={searchItems}
              onChange={(e) => setSearchItems(e.target.value)}
            />
          </div>
          <div className="mt-1 border">
            <DataTable
              columns={columns}
              data={filterdata}
              pagination
              paginationServer
              paginationTotalRows={totalRecords}
              paginationPerPage={15}
              paginationRowsPerPageOptions={[15, 30, 50]}
              onChangePage={(current) => setCurrentPage(current)}
              selectableRowsHighlight
              subHeaderAlign="left"
              highlightOnHover
            />
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                placeholder="Remarks"
                className="w-100 form-control"
                value={Remarks}
                onChange={(e) => setremarks(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={updateremarks}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
